import { NgModule } from '@angular/core';
import { KvpPipe } from './kvp.pipe';

@NgModule({
  imports: [],
  declarations: [KvpPipe],
  exports: [KvpPipe],
})

export class KvpPipeModule {
  static forRoot() {
    return {
      ngModule: KvpPipeModule,
      providers: [],
    };
  }  
}
