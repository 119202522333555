import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'search'
})

export class SearchPipe implements PipeTransform {
  transform(array: any[], key: string, value: string): any[] {
    if (array && key && value) {
      const result = array.filter(arrayItem => arrayItem[key] == value);
      return result;
    }
    return null;
  }
}
