import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Headers } from '@angular/http';

@Injectable({
  providedIn: 'root'
})
export class IntegrationsService {
  user;

  constructor(private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async getIntegrations() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/integrations/`).toPromise();
  }

  async getBlueJeansApp() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/bluejeans/app`).toPromise();
  }
  
  async updateApp(update) {
    return this.http.put<any[]>(`${this.backendConfig.url}api/bluejeans/app`, update).toPromise();
  }

  async createApp() {
    return this.http.post<any[]>(`${this.backendConfig.url}api/bluejeans/app`, {}).toPromise();
  }

  async getIntegrationsWithType(type) {
    return this.http.get<any[]>(`${this.backendConfig.url}api/integration-type/${type}`).toPromise();
  }

  async addIntegration(name, type, data) {
    const body = {
      friendlyName: name,
      type: type,
      data: data
    };
    return this.http.post(`${this.backendConfig.url}api/integration/`, body).toPromise();
  }

  async deleteIntegration(integrationId: string) {
    return this.http.delete(this.backendConfig.url + 'api/integration/' + integrationId).toPromise();
  }

  async getIntegration(integrationId: string) {
    return this.http.get(this.backendConfig.url + 'api/integration/' + integrationId).toPromise();
  }

  async saveIntegration(integration) {
    return this.http.put(`${this.backendConfig.url}api/integration/${integration._id}`, integration).toPromise();
  }
  
  async testIntegration(integration) {
    return this.http.post<any>(`${this.backendConfig.url}api/test-integration/`, integration).toPromise();
  }

  async getOpsgenieTeamMembers(teamName, apiKey) {
    const body = {
      teamName: teamName,
      apiKey: apiKey
    };
    return this.http.post<any[]>(`${this.backendConfig.url}api/opsgenie-team/`, body).toPromise();
  }

  async resgisterSlack(code, redirectUri) {
    const body = {
      code: code,
      redirectUri: redirectUri
    };
    return this.http.post<any[]>(`${this.backendConfig.url}api/slack-registration/`, body).toPromise();
  }

  async getConnectwiseCompanies(integrationId) {
    return this.http.get<string>(`${this.backendConfig.url}api/connectwise-companies/${integrationId}`).toPromise();
  }

}
