import { Inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class EmailService {

  constructor(private http: HttpClient,
    @Inject('BACKEND_CONFIG') private backendConfig
  ) { }

  async addEmail(name) {
    const body = { name: name };
    return this.http.post(`${this.backendConfig.url}api/email/`, body).toPromise();
  }

  async getEmails() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/emails/`).toPromise();
  }

  async deleteEmail(emailId: string) {
    return this.http.delete(this.backendConfig.url + 'api/email/' + emailId).toPromise();
  }

  async saveEmail(email) {
    return this.http.put(`${this.backendConfig.url}api/email/${email._id}`, email).toPromise();
  }

  async sendEmail(email) {
    const body = { emailId: email._id };
    return this.http.post<any>(`${this.backendConfig.url}api/send-email/`, body).toPromise();
  }

  async addAccount(name) {
    const body = { name: name };
    return this.http.post(`${this.backendConfig.url}api/email-account/`, body).toPromise();
  }

  async getAccounts() {
    return this.http.get<any[]>(`${this.backendConfig.url}api/email-accounts/`).toPromise();
  }

  async deleteAccount(accountId: string) {
    return this.http.delete(this.backendConfig.url + 'api/email-account/' + accountId).toPromise();
  }

  async saveAccount(account) {
    return this.http.put(`${this.backendConfig.url}api/email-account/${account._id}`, account).toPromise();
  }

  async testAccount(accountId) {
    const body = { accountId: accountId };
    return this.http.post<any>(`${this.backendConfig.url}api/email-account-test/`, body).toPromise();
  }

}
